<template>
  <span v-html="updatedHtml" v-if="signatureOnly" />
  <v-card
    :dark="setPreviewDark"
    :outlined="outlined"
    :tile="tile"
    :flat="flat"
    v-else
    class="mx-auto overflow-y-auto"
  >
    <v-toolbar color="grey darken-3" :dark="!toolbarLight" dense flat>
      <h5 v-if="showUserSelection">{{ $t("common.viewingModelAs") }}</h5>
      <h4 v-else>{{ title }}</h4>

      <v-select
        v-if="showUserSelection"
        v-model="selectedUser"
        :items="users"
        solo
        flat
        height="28"
        elevation="0"
        background-color="transparent"
        :label="$t('common.viewingModelAs')"
        item-text="name"
        hide-details
        return-object
        :dense="$vuetify.breakpoint.xs"
      >
        <template v-slot:selection="data">
          <v-list-item
            class="mx-0"
            :key="JSON.stringify(data.item)"
            v-bind="data.attrs"
          >
            <v-list-item-avatar class="ml-0 mr-2">
              <v-avatar left size="30">
                <img :src="data.item.photo || '@/assets/user-default.png'" />
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-body-2">
                {{ data.item.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="caption">
                {{ data.item.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-avatar class="ml-0 mr-2">
            <v-avatar left size="30">
              <img :src="item.photo || '@/assets/user-default.png'" />
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-select>

      <v-spacer />

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="setPreviewDark = !setPreviewDark"
            icon
          >
            <v-icon
              v-text="
                setPreviewDark ? 'mdi-white-balance-sunny' : 'mdi-weather-night'
              "
            />
          </v-btn>
        </template>
        <span
          >{{ $t("common.viewInTheme") }}
          {{ setPreviewDark ? $t("common.light") : $t("common.dark") }}</span
        >
      </v-tooltip>

      <v-tooltip v-if="showExpand" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="showExpandedVersion = true"
            icon
          >
            <v-icon v-text="'mdi-arrow-expand'" />
          </v-btn>
        </template>
        <span v-text="$t('common.viewInFullscren')" />
      </v-tooltip>
    </v-toolbar>

    <v-divider v-if="toolbarLight" />

    <div v-if="!hideTop">
      <v-card-subtitle v-if="!subject" class="py-1">
        <span style="text-transform: capitalize">{{ $t("common.to") }}</span>
        <span
          class="px-2"
          v-html="
            userTags?.email
              ? `${userTags.name} (${userTags.email})`
              : `${currentUser.name} (${currentUser.email})`
          "
        />
      </v-card-subtitle>
      <v-divider v-if="!subject" class="mx-4" />

      <v-card-subtitle v-if="!subject" class="py-1">
        <span style="text-transform: capitalize">{{ $t("common.from") }}</span>
        <span v-if="from" class="px-2" v-text="from" />
      </v-card-subtitle>
      <v-divider v-if="!subject" class="mx-4" />

      <v-card-subtitle class="py-1">
        {{ $t("common.subject") }}
        <span class="px-2">{{
          subject || $t("common.checkTheNewSignature")
        }}</span>
      </v-card-subtitle>
      <v-divider class="mx-4" />
    </div>

    <v-card
      :dark="setPreviewDark"
      :max-height="maxHeight"
      min-height="300px"
      flat
      class="px-4 pt-6 pb-2 overflow-y-auto ignore-css"
    >
      <v-skeleton-loader
        v-if="loading || localLoading"
        type="article, actions"
      ></v-skeleton-loader>
      <span
        class="mobile-scale"
        v-else-if="!loading && !localLoading && updatedHtml"
        v-html="updatedHtml"
      ></span>
      <Alert
        v-if="!updatedHtml && !loading && localLoading"
        dense
        color="error"
      >
        {{ $t("common.thisModelEmpty") }}
      </Alert>
    </v-card>

    <v-card-actions
      v-if="!hideBottom"
      class="mx-2 pb-2"
      id="sizeOfScreenForButtons"
    >
      <v-btn
        color="primary"
        disabled
        class="px-3 text-none font-weight-medium text-body-1"
        rounded
      >
        Enviar
        <v-divider vertical class="mx-2" />
        <v-icon v-text="'mdi-menu-down'" small />
      </v-btn>
      <v-btn icon class="mx-2" disabled>
        <v-icon>mdi-rotate-270 mdi-attachment</v-icon>
      </v-btn>
      <v-btn icon class="mr-2" disabled>
        <v-icon>mdi-link</v-icon>
      </v-btn>
      <v-btn icon class="mr-2" disabled>
        <v-icon>mdi-emoticon-outline</v-icon>
      </v-btn>
      <v-btn icon class="mr-2" disabled>
        <v-icon>mdi-google-drive</v-icon>
      </v-btn>
      <v-btn icon class="mr-2" disabled>
        <v-icon>mdi-image-outline</v-icon>
      </v-btn>
      <v-btn icon class="mr-2" disabled>
        <v-icon>mdi-lock-outline</v-icon>
      </v-btn>
      <v-btn icon class="mr-2" disabled>
        <v-icon>mdi-pen-outline</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn icon class="mr-2" disabled>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
      <v-btn icon class="mr-0" disabled>
        <v-icon>mdi-delete-outline</v-icon>
      </v-btn>
    </v-card-actions>

    <v-divider></v-divider>

    <v-dialog
      v-model="showExpandedVersion"
      fullscreen
      hide-overlay
      :dark="setPreviewDark"
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-toolbar dark>
          <v-btn icon dark @click="showExpandedVersion = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title class="text-truncate"
            >{{ !sendasPreview ? $t("common.viewingModel") : "" }} {{ title }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn @click="setPreviewDark = !setPreviewDark">
            <v-icon
              left
              v-text="
                setPreviewDark ? 'mdi-white-balance-sunny' : 'mdi-weather-night'
              "
            />
            {{ $t("common.viewInTheme") }}
            {{ setPreviewDark ? $t("common.light") : $t("common.dark") }}
          </v-btn>
        </v-toolbar>

        <div v-if="isVacationTemplate">
          <v-card-subtitle class="py-1">
            {{ $t("common.to") }}
            <span
              class="px-2"
              v-html="`${currentUser.name} (${currentUser.email})`"
            />
          </v-card-subtitle>
          <v-divider class="mx-4" />

          <v-card-subtitle class="py-1" v-text="$t('common.from')" />
          <v-divider class="mx-4" />

          <v-card-subtitle class="py-1">
            Assunto
            <span class="px-2">{{
              subject || "Verifique a nova mensagem de férias!"
            }}</span>
          </v-card-subtitle>
          <v-divider class="mx-4" />
        </div>
        <v-card flat class="px-6 pt-4 pb-2 overflow-y-auto transparent">
          <v-skeleton-loader
            v-if="loading || localLoading"
            type="table-heading, list-item-two-line, image"
          ></v-skeleton-loader>
          <span v-else v-html="updatedHtml"></span>
        </v-card>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { fluxTemplateToPreview } from "@/helpers/services/tagPreview";
import { mapGetters } from "vuex";

export default {
  name: "TemplatePreview",

  props: {
    html: { type: String, default: "" },
    subject: { type: String, default: "" },
    title: { type: String, default: "" },
    signatureOnly: { type: Boolean, default: false },
    hideBottom: { type: Boolean, default: false },
    hideTop: { type: Boolean, default: false },
    showExpand: { type: Boolean, default: false },
    maxHeight: { type: [String, Number], default: "auto" },
    loading: { type: Boolean, default: false },
    infoToRender: { type: [Object, Boolean], default: false },
    isVacationTemplate: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    flat: { type: Boolean, default: false },
    tile: { type: Boolean, default: false },
    showUserSelection: { type: Boolean, default: false },
    toolbarLight: { type: Boolean, default: false },
    sendasPreview: { type: Boolean, default: false },
    from: { type: String, default: "" },
  },

  data() {
    return {
      showExpandedVersion: false,
      setPreviewDark: false,
      localLoading: false,
      user: {},
    };
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "company",
      "users",
      "companyInTrial",
      "currentPlanIsBasicFree",
    ]),

    selectedUser: {
      get() {
        return this.user;
      },
      set(user) {
        this.user = user;
      },
    },

    showWatermark() {
      return this.currentPlanIsBasicFree || this.companyInTrial;
    },

    userTags() {
      return this.infoToRender || this.selectedUser;
    },

    updatedHtml() {
      return fluxTemplateToPreview(
        this.html,
        this.userTags,
        this.showWatermark
      );
    },
  },

  watch: {
    showExpandedVersion() {
      this.setPreviewDark = false;
    },

    infoToRender: function (newVal, oldVal) {
      if (newVal.name !== oldVal?.name) {
        this.localLoading = true;
        this.$forceUpdate();
        this.localLoading = false;
      }
    },
  },

  beforeMount() {
    this.user = this.currentUser;
  },
};
</script>
<style scoped>
@media screen and (max-width: 650px) {
  #sizeOfScreenForButtons {
    display: none;
  }
}
@media screen and (max-width: 425px) {
  .mobile-scale {
    zoom: 0.8;
    -moz-transform: scale(0.8);
    transform: scale(0.8);
  }
}
</style>
