<template>
  <v-banner
    v-if="!hasAppliedSignature"
    single-line
    transition="slide-y-transition"
    style="cursor: pointer"
    outlined
    class="rounded-lg"
  >
    <v-avatar slot="icon" color="primary" size="40">
      <v-icon icon="mdi-lock" color="white"> mdi-comment-question </v-icon>
    </v-avatar>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="primary--text font-weight-medium">
          {{ title }}
        </v-list-item-title>

        <v-list-item-subtitle>
          {{ subTitle }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <template v-slot:actions="{ dismiss }">
      <v-btn
        text
        class="text-none text-body-2 font-weight-medium"
        color="secondary lighten-2"
        @click="dismiss"
      >
        Dispensar
      </v-btn>
      <v-btn
        color="accent"
        class="text-none text-body-2 font-weight-medium px-6"
        @click="$router.push({ name: 'ContactUs' })"
      >
        Ajuda
        <v-icon right size="18"> mdi-arrow-right </v-icon>
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HelpBanner",

  props: {
    title: {
      type: String,
      default: "Dúvida sobre como utilizar a ferramenta?",
    },
    subTitle: {
      type: String,
      default: "Esclareça suas dúvidas com um de nossos especialistas via chat",
    },
  },

  computed: {
    ...mapGetters(["hasAppliedSignature"]),
  },
};
</script>

<style scoped></style>
